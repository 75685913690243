/* html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}  */



/* @keyframes reflowFix {
  from { opacity: 0.99; }
  to { opacity: 1; }
} 
.header svg {
  animation: reflowFix 0.01s linear;
}  */

.customShadow{
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

/* below is the design for the product category select box */


.select-box {
padding: 3px 0 3px 6px; /* Padding to ensure space around the text */
max-width: 116px;
font-size: 12px;
font-weight: 700; /* Bold text */
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
border-width: 0px .10px 0px 2px; /* Top, right, bottom, left */
border-style: solid;
}


/* Default styling for large screens */
.search-container .searchCategory {
flex: 0 0 auto;
}

.search-container input {
flex-grow: 1;
min-width: 150px; /* Ensure the input doesn't get too small */
}

/* full width for contain div  */
/* @media (min-width: 300px) {
.container {
     max-width: 100%;
}
} */

.local-market-option {
  background-color: rgba(0, 0, 0, 0); /* Fully transparent background */
}

/* Responsive adjustments */
@media (min-width: 1036px) {
.search-container{
  width: 100%;
  max-width: 550px;
}

}

@media (min-width: 976px) and (max-width: 1035px) {
.search-container{
  width: 100%;
  max-width: 480px;
}

}


@media (max-width: 390px) {
  .logoCustom-300-390{
    width: 130px;
    height: 80px;
    padding-bottom: 1rem;
   padding-right: 0px;
  }
  .market{
    width: 24%;
    
  }

  .user-profile{
    font-size: small;
  }

  .local-market{
    font-size: small;
  }

  .local-market-option{
    padding-left: -5rem;
    font-size: x-small;
  }
}



.user-profile > *:not(:first-child):empty {
  display: none; /* Prevent gaps if any empty elements accidentally remain */
  
}





/**user table***/
.userTable{
@apply bg-white
}

.userTable th{
@apply border
}

.userTable td{
@apply border text-base text-center
}

/**scrollbar hidden***/
.scrollbar-none::-webkit-scrollbar{
display: none;
}

/* error container styling */

.error-container {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: calc(100vh - 200px); /* Adjust based on your header/footer */
text-align: center;
}

.error-container h1 {
font-size: 2rem;
margin-bottom: 1rem;
}

.error-container p {
font-size: 1.2rem;
margin-bottom: 2rem;
}

.error-container a {
color: white;
background-color: #007bff;
padding: 10px 20px;
text-decoration: none;
border-radius: 5px;
}

/* cookie consent design */
.cookie-consent-content {
max-width: 500px;
padding: 20px;
background: white;
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cookie-consent-content p {
font-size: 16px;
margin-bottom: 10px;
color: #333;
}

.cookie-consent-content button {
font-size: 16px;
padding: 10px 20px;
border: none;
background-color: #ff7d1a;
color: white;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.cookie-consent-content button:hover {
background-color: #ff4500;
}

.cookie-consent-content a {
color: #ff7d1a;
text-decoration: underline;
}

.cookie-consent-content a:hover {
color: #ff4500;
}